<template>
  <v-container fluid>
    <!---BOC:[breadcrumb]-->
    <ABreadcrumb :items="breadcrumbTabulator.items" />
    <!--EOC-->
    <!---BOC:[header]-->
    <BreadHeader :title="`Edit ${modelName}`"></BreadHeader>
    <!--EOC-->
    <!--BOC:[error]-->
    <AError :api="apiRead" />
    <!--EOC-->
    <!---BOC:[form]-->
    <BreadTabulatorForm
      :isLoading="apiRead.isLoading"
      ref="form"
      action="edit"
      :api="api"
      :model="model"
    />
    <!--EOC-->
    <!---BOC:[note]-->
    <ADevNote
      :text="[
        'Validation',
        ['✗ Validate authority', '✗ Validate ownership'],
        'Layout',
        ['✓ Show breadcrumbs', '✓ Show title', '✓ Replace model item name'],
        'Read',
        ['✓ Run API Read', '✓ Show API Read Loading', '✓ Show API Read Error'],
        'Form',
        [
          '✓ Show Form',
          '✓ Get Form Field',
          '✓ Get Form Rules',
          '✓ Get Form Data',
        ],
        'Action',
        [
          '✓ Form Button isLoading',
          '✓ Run Action API',
          '✓ Show Action API Loading',
          '✓ Show Action API Error',
        ],
        'Parent',
        [
          '✓ Fetch data',
          '✓ Show loading',
          '✗ Show error',
          '✓ Show null',
          '✗ Select default value',
        ],
      ]"
    />
    <!--EOC-->
  </v-container>
</template>

<script>
//BOC:[api]
import Api from "@/objects/api";
//EOC
//BOC:[breadcrumb]
import BreadcrumbTabulator from "@/objects/breadcrumbTabulator";
//EOC
//BOC:[model]
import Model from "@/objects/model";
//EOC
//BOC:[service]
import Service from "@/objects/service";
//EOC
import { mapState } from "vuex";
export default {
  components: {
    //
  },
  computed: mapState({
    services: (state) => state.service.data.services,
  }),
  props: {
    //
  },
  data: () => ({
    //BOC:[apiRead]
    apiRead: new Api(),
    //EOC
    //BOC:[api]
    api: new Api(),
    //EOC
    //BOC:[breadcrumbs]
    breadcrumbTabulator: new BreadcrumbTabulator(),
    //EOC
    //BOC:[model]
    model: new Model(),
    modelId: null,
    modelName: null,
    //EOC
    //BOC:[service]
    service: new Service(),
    apiReadUrl: '',
    apiUpdateUrl:''
    //EOC
  }),
  created() {
    //BOC:[model]
    this.model.getByKey(this.$route.params.modelKey);
    this.modelId = this.$route.params.modelId;
    this.modelName = `${this.model.name.singular} ${this.modelId}`;
    //EOC
    //BOC:[service]
    if (this.model.serviceKey) this.service.set("key", this.model.serviceKey);
    //EOC
    //BOC:[breadcrumbs]
    this.breadcrumbTabulator.addForOperationEdit({
      services: this.services,
      model: this.model,
      modelId: this.modelId,
    });
    //EOC
    //BOC:[apiRead]

    if(this.model.apiUrls.apiReadUrl != ''){
      this.apiReadUrl = `${this.$service[this.service.key]}${this.model.apiUrls.apiReadUrl}/${this.modelId}/read`;
    }else{
      this.apiReadUrl = `${this.$service[this.service.key]}/v1/en/console/tabulator/model/${this.model.key}/${this.modelId}/read`;
    }

    if(this.model.apiUrls.apiUpdateUrl != ''){
      this.apiUpdateUrl = `${this.$service[this.service.key]}${this.model.apiUrls.apiUpdateUrl}/${this.modelId}/edit`;
    }else{
      this.apiUpdateUrl = `${this.$service[this.service.key]}/v1/en/console/tabulator/model/${this.model.key}/${this.modelId}/edit`;
    }

    this.apiRead.setUrl(
      this.apiReadUrl
    );
    this.apiRead.setCallbackCompleted((resp) => {
      this.$refs.form.setData(resp);
      if (resp.name) this.modelName = resp.name;
    });
    this.apiRead.fetch();
    //EOC
    //BOC:[api]
    this.api.setMethod(`POST`);
    this.api.setUrl(
      this.apiUpdateUrl
    );
    this.api.setCallbackCompleted(() => {
      this.$router.push({
        name: "PageServiceModelBreadTabulatorRead",
        params: {
          serviceKey: this.model.serviceKey,
          modelKey: this.model.key,
          modelId: this.modelId,
        },
      });
      this.$store.dispatch("showMessage", {
                    message: "Updated successfully.",
                    messageType: "success",
                    timeout:2000
                });
    });
    //EOC
  },
  mounted() {
    //
  },
  methods: {
    //
  },
};
</script>